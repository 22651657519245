.swiper-slide {
  display: block;
  width: auto;
  margin-right: 8px;
  padding: 40px 0;
}

.swiper-pagination {
  padding-top: 20px;
}

.swiper-button-prev {
  left: 25px;
}

.swiper-button-next {
  right: 25px;
}

.swiper-container {
  width: 100%;
  display: flex;
  align-items: center;
  margin: auto;
  flex-wrap: wrap;
}

@media screen and(max-width: 650px) {
  .swiper-container {
    flex-direction: column;
  }
}

.swiper-pagination-bullet {
  margin: 0 10px;
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 80%;
  background: rgb(55, 86, 88);
  opacity: 0.4;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: rgb(0, 78, 141);
}
